import { Injectable } from '@angular/core';
import { Chart } from 'chart.js';
// import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import * as doughnutlabel from 'chartjs-plugin-doughnutlabel';
import { DateTime } from 'luxon';

const DEFAULT_COLORS1 = ['#f08700', '#f49f0a', '#efca08', '#00a6a6', '#bbdef0'];
const DEFAULT_COLORS2 = ['#79aada', '#c8cfd6'];

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  baseConfig: Chart.ChartConfiguration = {
    plugins: [doughnutlabel],
    type: 'doughnut',
    options: {
      responsive: true,
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      plugins: {
        doughnutlabel: {
          labels: [
            {
              text: this.calculateChart,
              font: {
                size: '40',
                weight: 'bold'
              },
              color: 'white'
            }
          ]
        }
      }
    }
  };

  constructor() { }

  buildEfisiensi(divId: string, values: any[]) {
    return new Chart(divId, {
      plugins: [doughnutlabel],
      type: 'doughnut',
      data: {
        datasets: [{
          data: [values[1], values[2]],
          backgroundColor: DEFAULT_COLORS2,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          doughnutlabel: {
            labels: [
              {
                text: values[0],
                font: {
                  size: '40',
                  weight: 'bold'
                },
                color: 'white'
              }
            ]
          }
        }
      }
    });
  }

  buildAvailability(divId: string, values: any[]) {
    return new Chart(divId, {
      plugins: [doughnutlabel],
      type: 'doughnut',
      data: {
        datasets: [{
          data: [values[1], values[2]],
          backgroundColor: DEFAULT_COLORS2,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          doughnutlabel: {
            labels: [
              {
                text: values[0],
                font: {
                  size: '40',
                  weight: 'bold'
                },
                color: 'white'
              }
            ]
          }
        }
      }
    });
  }

  buildPerformance(divId: string, values: any[]) {
    return new Chart(divId, {
      plugins: [doughnutlabel],
      type: 'doughnut',
      data: {
        datasets: [{
          data: [values[1], values[2]],
          backgroundColor: DEFAULT_COLORS2,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          doughnutlabel: {
            labels: [
              {
                text: values[0],
                font: {
                  size: '40',
                  weight: 'bold'
                },
                color: 'white'
              }
            ]
          }
        }
      }
    });
  }

  buildQuality(divId: string, values: any[]) {
    return new Chart(divId, {
      plugins: [doughnutlabel],
      type: 'doughnut',
      data: {
        datasets: [{
          data: [values[1], values[2]],
          backgroundColor: DEFAULT_COLORS2,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          doughnutlabel: {
            labels: [
              {
                text: values[0],
                font: {
                  size: '40',
                  weight: 'bold'
                },
                color: 'white'
              }
            ]
          }
        }
      }
    });
  }

  buildOEE(divId: string, values: any[]) {
    return new Chart(divId, {
      plugins: [doughnutlabel],
      type: 'doughnut',
      data: {
        datasets: [{
          data: [values[1], values[2]],
          backgroundColor: DEFAULT_COLORS2,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          doughnutlabel: {
            labels: [
              {
                text: values[0],
                font: {
                  size: '40',
                  weight: 'bold'
                },
                color: 'white'
              }
            ]
          }
        }
      }
    });
  }

  private calculateChart(chart) {
    const sum = chart.config.data.datasets[0].data.reduce((a, b) => a + b, 0);
    return sum;
  }
}
