import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../env/env.service';
import { Observable } from 'rxjs/internal/Observable';
import { from, interval } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';

const POLLING_INTERVAL = 6000; // in milliseconds

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(
    private httpClient: HttpClient,
    private env: EnvService) {
    //
  }

  getData(id: string) {
    if (id) {
      const url = this.env.apiUrl + '/mobility_order_monitor?display_group=' + id;
      return this.httpClient.get(url);
    }

    return null;
  }

  getJSON(id: string): Observable<any> {
    return this.httpClient.get("./assets/data/dummy.json");
  }
}
