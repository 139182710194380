import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  // API url
  // public apiUrl = 'http://localhost:5059/api/v1';
  public apiUrl = 'https://staging-agp-api.nitoza.com/mobility_dashboard/api/v1';

  // Socket url
  // public socketUrl = 'http://localhost:5005';
  public socketUrl = 'https://staging-agp-tv-socket.nitoza.com';

  // Polling interval (default 5 minutes)
  public interval = 5000;

  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() { }
}
