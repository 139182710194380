import { Component, OnInit, AfterViewInit, ElementRef, ViewChildren, QueryList, Input } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { interval } from 'rxjs/internal/observable/interval';
import { startWith, mergeMap, catchError } from 'rxjs/operators';
import { DateTime, Duration, Interval } from 'luxon';
import { EnvService, ChartService } from '../services';
import { SimpleCounter } from '../models/simple-counter';
import { Mobility, ResultData } from '../models/line-group';

import * as moment from 'moment';

@Component({
  selector: 'mobility-tv-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  providers: [
    ChartService
  ]
})
export class DisplayComponent implements OnInit, AfterViewInit {
  title = 'mobility-tv';
  currentDate;
  currentHours;
  hasContent = false;
  loadingText = 'Fetching data ...';

  serverTime = null;
  elapsedTimeInSeconds = 0;
  leftTimeInSeconds = 0;
  visibleMinusLeftTime = false;
  startAt: DateTime;
  targetFinishAt: DateTime;
  listMobilityOrders: any[] = [];
  isPause: boolean = false;
  counterUpdate: number = 0;

  @ViewChildren('efisiensi_chart', { read: ElementRef })
  efficiencyChartElementRefs: QueryList<ElementRef>;

  @ViewChildren('availability_chart', { read: ElementRef })
  availabilityChartElementRefs: QueryList<ElementRef>;

  @ViewChildren('performance_chart', { read: ElementRef })
  performanceChartElementRefs: QueryList<ElementRef>;

  @ViewChildren('quality_chart', { read: ElementRef })
  qualityChartElementRefs: QueryList<ElementRef>;

  @ViewChildren('oee_chart', { read: ElementRef })
  oeeChartElementRefs: QueryList<ElementRef>;

  charts: Chart[] = [];

  private lineGroup: string;
  @Input() results: Mobility[] = [];

  counter: SimpleCounter = {
    downtime: 0,
    downtime_rate: 0,
    elapsed_time_second: 0,
    elapsed_time_minute: 0,
    time_left_second: 0,
    time_left_minute: 0,
    production_efficiency_rate: 0,
    performance_rate: 0,
    production_availability_rate: 0,
    quality_rate: 0,
    oee_rate: 0
  };

  constructor(
    private env: EnvService,
    private chartService: ChartService
  ) {
    //
  }

  ngOnInit() {
    // get param
    this.lineGroup = this._getParamValueQueryString('line_group');
    this.fillInProperties();
    this.startClock();
  }

  ngAfterViewInit() {
    this._run();
  }

  reload() {
    window.location.reload();
  }

  private startClock() {
    interval(1000)
      .pipe(
        startWith(0),
    ).subscribe((val) => {
      if (this.counterUpdate < (this.env.interval / 1000)) {
        this.counterUpdate++;
      } else {
        this.counterUpdate = 0;
        this.fillInProperties();
      }

      const now = DateTime.local().setLocale('ID');

      this.currentDate = now.weekdayLong + ', ' + now.toFormat('dd-MMM-yyyy');
      this.currentHours = now.toFormat('HH:mm:ss');

      if (this.serverTime) {
        this.serverTime = moment(this.serverTime).add(1, 'seconds');
        this.currentDate = this.serverTime.lang('ID').format('dddd, DD-MMM-YYYY');
        this.currentHours = this.serverTime.format('HH:mm:ss');
      }

      if (!this.isPause && this.elapsedTimeInSeconds > 0) {
        this.elapsedTimeInSeconds++;

        if (this.leftTimeInSeconds <= 0) {
          this.leftTimeInSeconds = Math.abs(this.leftTimeInSeconds);
          this.visibleMinusLeftTime = true;
        }

        if (!this.visibleMinusLeftTime) {
          this.leftTimeInSeconds--;

          if (this.leftTimeInSeconds < 0) {
            this.visibleMinusLeftTime = true;
          }
        } else {
          this.leftTimeInSeconds++;
        }
      }
    });
  }

  private _run() {
    this._startBuildingChart(this.lineGroup);
  }

  private _getChartValue(val: number) {
    let dataMax = 100
    let dataMin = 0

    if (val < 100) {
      dataMax = val
      dataMin = 100 - val
    }

    return [val, dataMax, dataMin]
  }

  private _startBuildingChart(lineGroup: string) {
    this.efficiencyChartElementRefs.map((chartElementRef, index) => {
      return this.chartService.buildEfisiensi(chartElementRef.nativeElement, this._getChartValue(this.counter.production_efficiency_rate));
    });

    this.availabilityChartElementRefs.map((chartElementRef, index) => {
      return this.chartService.buildAvailability(chartElementRef.nativeElement, this._getChartValue(this.counter.performance_rate));
    });

    this.performanceChartElementRefs.map((chartElementRef, index) => {
      return this.chartService.buildPerformance(chartElementRef.nativeElement, this._getChartValue(this.counter.production_availability_rate));
    });

    this.qualityChartElementRefs.map((chartElementRef, index) => {
      return this.chartService.buildQuality(chartElementRef.nativeElement, this._getChartValue(this.counter.quality_rate));
    });

    this.oeeChartElementRefs.map((chartElementRef, index) => {
      return this.chartService.buildOEE(chartElementRef.nativeElement, this._getChartValue(this.counter.oee_rate));
    });
  }

  private _getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue: string;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  private fillInProperties() {
    this.counter.downtime = Number(this.results[0].downtime.toFixed(2));
    this.counter.downtime_rate = Number(this.results[0].downtime_rate.toFixed(2));
    this.counter.elapsed_time_second = Number(this.results[0].elapsed_time_second.toFixed(2));
    this.counter.elapsed_time_minute = Number(this.results[0].elapsed_time_minute.toFixed(2));
    this.counter.time_left_second = Number(this.results[0].time_left_second.toFixed(2));
    this.counter.time_left_minute = Number(this.results[0].time_left_minute.toFixed(2));
    this.counter.production_efficiency_rate = Number(this.results[0].production_efficiency_rate.toFixed(2));
    this.counter.performance_rate = Number(this.results[0].production_availability_rate.toFixed(2));
    this.counter.production_availability_rate = Number(this.results[0].performance_rate.toFixed(2));
    this.counter.quality_rate = Number(this.results[0].quality_rate.toFixed(2));
    this.counter.oee_rate = Number(this.results[0].oee_rate.toFixed(2));

    this.elapsedTimeInSeconds = this.counter.elapsed_time_second;

    this.visibleMinusLeftTime = false;
    this.leftTimeInSeconds = this.counter.time_left_second;
    if (this.counter.time_left_second < 0) {
      this.visibleMinusLeftTime = true;
      this.leftTimeInSeconds = this.leftTimeInSeconds * -1;
    }

    this.isPause = this.results[0].is_pause;

    const serverTime = this.results[0] && this.results[0].server_time || null;
    this.serverTime = null;
    if (serverTime && moment(serverTime).isValid()) {
      this.serverTime = moment(serverTime);
    }

    this.startAt = this.results[0] && this.results[0].start_at || null;
    this.targetFinishAt = this.results[0] && this.results[0].target_finish_at || null;

    const listMobilityOrders = this.results[0].mobility_orders || [];
    const maxListMobilityOrders = 10;
    listMobilityOrders.length = Math.min(listMobilityOrders.length, maxListMobilityOrders);
    this.listMobilityOrders = listMobilityOrders;
  }
}
