import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HttpParams } from '@angular/common/http';

// import { interval } from 'rxjs/internal/observable/interval';
import { interval, from, timer, Observable } from 'rxjs';
import { startWith, mergeMap, catchError, takeUntil, takeWhile, switchMap, concatMap, map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { EnvService, ChartService, DataService } from './services';
import { DisplayComponent } from './display/display.component';
import { Groupline, MobilityOrder, Mobility } from './models/line-group';
import { empty } from 'rxjs';
import { counter } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'mobility-tv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DataService]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  errorMessage = '';
  errorCode = '';

  private alive: boolean;
  private interval: number;
  private display: boolean;
  private polledData$: Observable<any>;

  @Input() results: Mobility[] = [];

  constructor(
    private env: EnvService,
    private dataService: DataService
  ) {
    //
    if (this.env.enableDebug) {
      console.log(`set polling interval: ${env.interval} ms`);
    }

    this.display = false;
    this.alive = true;
    this.interval = env.interval;
  }

  ngOnInit() {
    // fetch data
    // const group = '5d22b2c7995c3da301c15b48';

    this.errorCode = '';
    this.errorMessage = '';
    this.results = [];
    const id = this._getParamValue('p');
    if (!id) {
      this.errorMessage = 'Parameter not found';
      // tslint:disable-next-line: deprecation
      return empty();
    }

    this.runData();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private runData() {
    const data$ = this.dataService.getData(this._getParamValue('p'));
    // const data$ = this.dataService.getJSON(this._getParamValue('p'));
    const trigger$ = timer(0, this.env.interval);

    trigger$
      .pipe(
        switchMap(() => {
          return data$
            .pipe(
              catchError((err) => this.handleError(err))
            );
        })
      )
      .subscribe((res: Groupline) => {
        if (res && res.result && res.resultData.data.length > 0) {
          this.results = res.resultData.data;
          if (!this.display) {
            this.display = true;
          }

          console.log('>> group-line updated: ', res.resultData.last_update);
        } else {
          console.error('Invalid result.');
          this.errorMessage = 'Invalid result';
        }
      }, (err) => {
        console.error('HTTP error: ', err);
        this.errorCode = err && err.error && err.error.statusCode || err.status;
        this.errorMessage = err && err.error && err.error.message || err.statusText;
      }, () => {
        if (!this.errorMessage && this.results.length === 0) {
          this.errorMessage = 'Invalid parameter';
        }
        console.log('HTTP request completed.');
      });
  }

  private handleError(err) {
    console.error(err);
    if (err.status === 404) {
      throw err;
    }

    // tslint:disable-next-line: deprecation
    return empty();
  }

  private _getParamValue(paramName) {
    const url = window.location.href;
    let paramValue: string;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
}
