import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

  transform(value: number): string {
    let hours: string;
    let restMinutes: string;
    const onedayMinutes = 1440; //24*60

    if (value < 60) {
      return `00:${value}:00`;
    } else if (value > 60 && value < onedayMinutes) {
      hours = Math.floor(value / 60).toString();

      if (hours.length < 2) {
        hours = '0' + hours;
      }

      restMinutes = (value % 60).toString();
      if (restMinutes.length < 2) {
        restMinutes = '0' + restMinutes;
      }

      return `${hours}:${restMinutes}:00`;
    }
  }
}
