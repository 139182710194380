import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHms'
})
export class SecondsToHmsPipe implements PipeTransform {

  transform(value: number): string {
    const d = Number(value);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);

    const hDisplay = (h.toString().length < 2) ? '0' + h : h.toString();
    const mDisplay = (m.toString().length < 2) ? '0' + m : m.toString();
    const sDisplay = (s.toString().length < 2) ? '0' + s : s.toString();

    return hDisplay + ':' + mDisplay + ':' + sDisplay;
  }
}
