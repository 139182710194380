import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSelectModule } from 'ngx-select-ex';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { EnvServiceProvider } from './services';
import { DisplayComponent } from './display/display.component';
import { MinuteSecondsPipe } from './pipes/minute-second.pipe';
import { SecondsToHmsPipe } from './pipes/second-hms.pipe';

@NgModule({
  declarations: [
    AppComponent,
    DisplayComponent,
    MinuteSecondsPipe,
    SecondsToHmsPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSelectModule,
    FontAwesomeModule,
    ModalModule.forRoot()
  ],
  providers: [
    EnvServiceProvider,
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule {}
